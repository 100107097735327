import { Div, Label, Span } from "@tblabs/truffle";
import { RecruitRouter } from "../Services/RecruitRouter";
import { Section } from "../Components/Section";
import { OffersRepo } from "../Models/OffersRepo";
import { Center } from "../Components/Center";
import { DangerButton } from "../Components/DangerButton";
import { Box } from "../Components/Box";


export class OfferPreview extends Div
{
    constructor(private _repo: OffersRepo, private _router: RecruitRouter, private offerId: string)
    {
        super();
    }

    protected async OnAppend(): Promise<void>
    {
        const offer = await this._repo.GetOneOffer(this.offerId);

        if (!offer)
        {
            throw new Error(`No offer`);
        }

        this.Append(
            new Label(offer.Title).FontSize(32).Bold(),
            "\n",
            new Box(offer.IsRecruitmentOpen.value ? "✅ Rekrutacja otwarta" : "✖️ Rekrutacja zamknięta").Margin(8),
            ...offer.Description.Items
                .map(x => new Section(x.Label.value, [x.Text.value])),
            new Center(
                new DangerButton("Aplikuj!", () => this._router.GoToForm(offer))
                    .Enable(offer.IsRecruitmentOpen)
            ).MarginTop(32)
        )
    }
}
