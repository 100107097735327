import { Collection, RefSerializer } from "@tblabs/truffle";
import { RawJobOffer } from "./RawJobOffer";
import { JobOffer } from "./JobOffer";
import { JobOfferDescriptionEntry } from "./JobOfferDescriptionEntry";
import { JobOfferQuestionEntry } from "./JobOfferQuestionEntry";
import { ApplyForm, RawApplyForm } from "./OffersRepo";


export class JobOfferConverter
{
    public ToRaw(order: JobOffer): RawJobOffer
    {
        return RefSerializer.Flatenize(order) as unknown as RawJobOffer;
    }

    public OfferFromRaw(raw: RawJobOffer): JobOffer | null
    {
        try
        {
            const job = new JobOffer(raw.Id);

            job.Title.value = this.GetString(raw.Title);
            job.IsRecruitmentOpen.value = this.GetBool(raw.IsRecruitmentOpen);

            job.Description = this.GetList<JobOfferDescriptionEntry>(raw.Description, x => new JobOfferDescriptionEntry(x.Label, x.Text));
            job.Form = this.GetList<JobOfferQuestionEntry>(raw.Form, x => new JobOfferQuestionEntry(x.Question, x.Answer));

            return job;
        }
        catch (ex: any)
        {
            console.warn(`Invalid record: ${ex.message}`)
            return null;
        }
    }

    public ApplyFormFromRaw(raw: RawApplyForm): ApplyForm | null
    {
        try
        {
            const application = new ApplyForm(raw.Id);

            application.Position = this.GetString(raw.Position);
            application.Answers = this.GetList<JobOfferQuestionEntry>(raw.Answers, x => new JobOfferQuestionEntry(x.Question, x.Answer));
            application.Recruit.Name.value = this.GetString(raw.Recruit.Name);
            application.Recruit.Phone.value = this.GetString(raw.Recruit.Phone);
            application.Recruit.Email.value = this.GetString(raw.Recruit.Email);

            return application;
        }
        catch (ex: any)
        {
            console.warn(`Invalid record: ${ex.message}`)
            return null;
        }
    }

    private GetList<T>(value: any, creator: (raw: any) => T): Collection<T>
    {
        const collection = value?.map(x => creator(x));

        return new Collection<T>(collection);
    }

    private GetString(value: any): string
    {
        return value || "";
    }

    private GetNumber(value: any): number
    {
        return +value || 0;
    }

    private GetBool(value: any): boolean
    {
        return !!value || false;
    }

    private GetDate(date: any): Date
    {
        return date ? new Date(date) : new Date(0);
    }
}
