import { JobOffer } from "../Models/JobOffer";
import { Ref } from "@tblabs/truffle";
import { ApplyForm } from "../Models/OffersRepo";


export class AdminRouter
{
    GoToApplyView(x: ApplyForm): void
    {
        this.hash.value = `admin/apply/${x.Id}/view`;
    }
    GoToOfferEdit(offer: JobOffer): void
    {
        this.hash.value = `admin/offer/${offer.Id}/edit`;
    } 
    GoToOfferPreview(offer: JobOffer): void
    {
        this.hash.value = `job/${offer.Id}`;
    }
    private history: string[] = [];

    constructor(private hash: Ref<string>)
    { }
 
    public GoBack()
    {
        /*
        TO NIE DZIAŁA:

        history.back(); // cofa adres ale nie przeładowuje strony
        location.reload(); // to też nie przeładowuje
        */
        this.history.pop();
        this.hash.value = this.history.pop() || "admin/offers";
    }
}

