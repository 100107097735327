import { Div } from "@tblabs/truffle";
import { appendable } from "@tblabs/truffle/Core/appendable";
import { Header } from "./Header";

export class Section extends Div
{
    constructor(label, body: appendable[])
    {
        super();
        this.Style(``);
        this.Append(
            new Header(label),
            new Div().Style(`padding: 12px`).Append(
                ...body
            )
        );
    }
}
