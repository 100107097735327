import { Div, Link } from "@tblabs/truffle";
import { Section } from "../Components/Section";
import { RecruitRouter } from "../Services/RecruitRouter";


export class NotExistingPage extends Div
{
    constructor(private _router: RecruitRouter)
    {
        super();

        this.Append(
            new Section("Błąd", ["Pod tym adresem nic nie ma. Ale jeśli interesują Cię aktualne oferty pracy w specTeam znajdziesz je ", new Link("tutaj").OnClick(() => _router.GoToJobs())])
        )
    }
}
