import { JobOfferConverter } from "./JobOfferConverter";
import { OnlineStorage } from "../Services/Storage/OnlineStorage";
import { DeleteCommand } from "../Services/Storage/Messages/DeleteCommand";
import { ListQuery } from "../Services/Storage/Messages/ListQuery";
import { UpdateCommand } from "../Services/Storage/Messages/UpdateCommand";
import { JobOffer } from "./JobOffer";
import { ReadQuery } from "../Services/Storage/Messages/ReadQuery";
import { RawJobOffer } from "./RawJobOffer";
import { UpdateResult } from "./UpdateResult";
import { JobOfferQuestionEntry } from "./JobOfferQuestionEntry";
import { Collection, RefSerializer, RefString } from "@tblabs/truffle";
import { v4 as uuidv4 } from 'uuid';

export interface QuestionAnswer
{
    Question: string;
    Answer: string;
}
export interface RawApplyForm 
{
    Id: string;
    Position: string;
    Answers: QuestionAnswer[];
    Recruit: { Name: string; Phone: string; Email: string };
}
export class Recruit
{
    public Name = new RefString();
    public Phone = new RefString();
    public Email = new RefString();

    constructor(name?, phone?, email?)
    {
        if (name)
            this.Name.value = name;
        if (phone)
            this.Phone.value = phone;
        if (email)
            this.Email.value = email;
    }
}
export class ApplyForm 
{
    public Id: string = "";
    public Position: string = "";
    public Answers = new Collection<JobOfferQuestionEntry>();
    public Recruit = new Recruit();

    constructor(id: string)
    {
        this.Id = id;
    }
}

export class OffersRepo 
{
    public cache: JobOffer[] = [];

    constructor(
        private _onlineStorage: OnlineStorage,
        private _converter: JobOfferConverter)
    { }

    public async GetAllOffers(): Promise<JobOffer[]>
    {
        const queryResult = await this._onlineStorage.SendMessage(new ListQuery("Offers", true));

        if (queryResult.IsSuccess && queryResult.Result)
        {
            const rawOrders: RawJobOffer[] = queryResult.Result;

            const jobs = rawOrders.map(x => this._converter.OfferFromRaw(x)).filter(x => x != null);

            this.cache = [...jobs as JobOffer[]];

            return this.cache;
        }

        throw new Error(`Could not fetch orders: ${queryResult.ErrorMessage}`);
    }

    public async GetAllApplys(): Promise<ApplyForm[]>
    {
        const queryResult = await this._onlineStorage.SendMessage(new ListQuery("Applys", true));

        if (queryResult.IsSuccess && queryResult.Result)
        {
            const rawOrders: RawApplyForm[] = queryResult.Result;

            const jobs = rawOrders.map(x => this._converter.ApplyFormFromRaw(x)).filter(x => x != null);

            return [...jobs as ApplyForm[]];
        }

        throw new Error(`Could not fetch orders: ${queryResult.ErrorMessage}`);
    }

    public async GetOneOffer(orderId: string): Promise<JobOffer | null>
    {
        const cached = this.cache.find(x => x.Id == orderId);

        if (cached)
        {
            return cached;
        }

        const queryResult = await this._onlineStorage.SendMessage(new ReadQuery(orderId, "Offers"));

        if (queryResult.IsSuccess)
        {
            const rawOrder = queryResult.Result;

            return this._converter.OfferFromRaw(rawOrder);
        }

        console.error(`Could not fetch order #${orderId}: ${queryResult.ErrorMessage} (folder: "Offers"})`);

        return null;
    }

    public async GetOneApply(id: string): Promise<ApplyForm | null>
    {
        const queryResult = await this._onlineStorage.SendMessage(new ReadQuery(id, "Applys"));

        if (queryResult.IsSuccess)
        {
            const rawOrder = queryResult.Result;

            return this._converter.ApplyFormFromRaw(rawOrder);
        }

        console.error(`Could not fetch order #${id}: ${queryResult.ErrorMessage} (folder: "Applys"})`);

        return null;
    }

    public async Update(order: JobOffer): Promise<UpdateResult>
    {
        const raw = this._converter.ToRaw(order)

        const commandResult = await this._onlineStorage.SendMessage(new UpdateCommand(order.Id, "Offers", raw, true));

        if (commandResult.IsSuccess == false)
        {
            // throw new Error(`Could not update Order #${order.Id}`);
            return new UpdateResult(false, commandResult.ErrorMessage)
        }

        return new UpdateResult(true);
    }

    public async AddApplyForm(form: ApplyForm): Promise<UpdateResult>
    {
        const raw = RefSerializer.Flatenize(form);

        const commandResult = await this._onlineStorage.SendMessage(new UpdateCommand(form.Id, "Applys", raw, true));

        if (commandResult.IsSuccess == false)
        {
            return new UpdateResult(false, commandResult.ErrorMessage)
        }

        return new UpdateResult(true);
    }

    public async Delete(id: string, folder: string): Promise<void>
    {
        const commandResult = await this._onlineStorage.SendMessage(new DeleteCommand(id, folder));

        if (commandResult.IsSuccess == false)
        {
            throw new Error(`Could not remove entry #${id} from database`);
        }
    }
}
