import { JobOffer } from "../Models/JobOffer";
import { Ref } from "@tblabs/truffle";

export class RecruitRouter
{
    public GoToJobs(): void
    {
        this.hash.value = `jobs`;
    }
    public GoToForm(offer: JobOffer)
    {
        this.hash.value = `job/${offer.Id}/apply`;
    }
    public GoToOfferPreview(offer: JobOffer): void
    {
        this.hash.value = `job/${offer.Id}`;
    }

    private history: string[] = [];

    constructor(private hash: Ref<string>) { }


    public GoBack()
    {
        /*
        TO NIE DZIAŁA:

        history.back(); // cofa adres ale nie przeładowuje strony
        location.reload(); // to też nie przeładowuje
        */
        this.history.pop();
        this.hash.value = this.history.pop() || "jobs";
    }
}
