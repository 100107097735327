"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableBuilder = void 0;
var __1 = require("../..");
var CollectionView_1 = require("../../Core/CollectionView");
var TableBody_1 = require("../Table/TableBody");
var TableCell_1 = require("../Table/TableCell");
var TableRow_1 = require("../Table/TableRow");
var Table_1 = require("./Table");
var TableColumn_1 = require("./TableColumn");
var TableHeaderV2_1 = require("./TableHeaderV2");
var TableBuilder = /** @class */ (function (_super) {
    __extends(TableBuilder, _super);
    function TableBuilder(data) {
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.columns = [];
        _this.body = new TableBody_1.TableBody();
        _this.whenEmpty = new __1.TextLabel("No results").Style("display: block; margin: 48px 0; text-align: center; white-space: nowrap;");
        if (data && data instanceof __1.Collection) {
            data = new CollectionView_1.CollectionView(data).Refresh();
        }
        _this.Class("uk-table uk-table-small uk-table-divider uk-table-middle");
        _this.Margin(0);
        data === null || data === void 0 ? void 0 : data.AddSubscriber(_this);
        return _this;
    }
    TableBuilder.prototype.Reset = function () {
        this.columns = [];
        this.Clear();
        return this;
    };
    TableBuilder.prototype.WhenEmpty = function (component) {
        this.whenEmpty = component;
        return this;
    };
    TableBuilder.prototype.Refresh = function () {
        var _this = this;
        var _a, _b, _c, _d;
        this.body.Clear();
        if (((_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.Items) === null || _b === void 0 ? void 0 : _b.length) === 0) {
            this.body.Append(new TableRow_1.TableRow()
                .Append(new TableCell_1.TableCell().Merge(this.columns.length)
                .Append(this.whenEmpty)));
        }
        else
            (_d = (_c = this === null || this === void 0 ? void 0 : this.data) === null || _c === void 0 ? void 0 : _c.Items) === null || _d === void 0 ? void 0 : _d.forEach(function (record) {
                var row = new TableRow_1.TableRow();
                _this.columns.forEach(function (c) {
                    var cell = new TableCell_1.TableCell();
                    var elem = c.Mapping(record, row, cell, _this.body, _this);
                    var m;
                    if (!Array.isArray(elem))
                        m = [elem];
                    else
                        m = elem;
                    m.forEach(function (e) {
                        cell.Append(e);
                    });
                    row.Append(cell);
                });
                _this.body.Append(row);
            });
    };
    TableBuilder.prototype.Build = function () {
        this.Append(new TableHeaderV2_1.TableHeaderV2(this.columns.map(function (x) { return x.Headers; })));
        this.Refresh();
        this.Append(this.body);
        return this;
    };
    TableBuilder.prototype.AddColumn = function (headers, mapping) {
        if (!Array.isArray(headers)) // TODO: extension .ToArray ?
            headers = [headers];
        this.columns.push(new TableColumn_1.TableColumn(headers, mapping));
        return this;
    };
    TableBuilder.Name = "TableBuilder";
    return TableBuilder;
}(Table_1.Table));
exports.TableBuilder = TableBuilder;
