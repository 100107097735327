import { Div, Label, Span } from "@tblabs/truffle";
import { Section } from "../Components/Section";
import { OffersRepo } from "../Models/OffersRepo";

export class ApplyView extends Div
{
    constructor(private _repo: OffersRepo, private offerId: string)
    {
        super();
    }

    protected async OnAppend(): Promise<void>
    {
        const application = await this._repo.GetOneApply(this.offerId);

        if (!application)
        {
            throw new Error(`No offer`);
        }

        this.Append(
            new Span(`Aplikacja na stanowisko`).FontSize(28),
            new Span(application.Position).FontSize(32).Bold().MarginLeft(8),
            ...application.Answers.Items
                .map(x => new Section(x.Question.value, [
                    new Label(x.Answer)
                ])),
            new Section("Dane kontaktowe kandydata",
                [
                    "Imię: ", new Label(application.Recruit.Name),
                    "\nTel.: ", new Label(application.Recruit.Phone),
                    "\nMail: ", new Label(application.Recruit.Email),
                ]
            )
        );
    }
}
