import { Snack } from "@tblabs/truffle";



export class MiniSnack extends Snack
{
    constructor(text: string)
    {
        super(text, "", { autoCloseTimeout: 3000, clickAnywhereToClose: true });
        // console.log(text);
        this.Style("max-width: 320px; height: 48px");
    }

    public Destroy()
    {
        super.RemoveSelf();
        return this;
    }
}
