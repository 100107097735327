import { Button } from "@tblabs/truffle";


export class PrimaryButton extends Button
{
    constructor(label, action?: (self: PrimaryButton)=>void)
    {
        super(label, action);
        this.DisplayInlineBlock();
        this.Class("uk-button uk-button-primary");
        this.Style("white-space: nowrap");
    }
}
