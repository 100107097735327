import { RefString } from "@tblabs/truffle";


export class JobOfferDescriptionEntry
{
    public Label = new RefString();
    public Text = new RefString();

    constructor(label, text)
    {
        this.Label.value = label || "";
        this.Text.value = text || "";
    }
}
