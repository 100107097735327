import { Button, Collection, DestroyingPatternContentSwitcher, Div, HashRef, RefString, TextInput } from "@tblabs/truffle";
import { NotExistingPage } from "./Pages/ErrorPage";
import { OfferApply } from "./Pages/OfferApply";
import { ApplyView } from "./Pages/ApplyView";
import { RecruitJobsListView } from "./Pages/RecruitJobsListView";
import { OfferPreview } from "./Pages/OfferPreview";
import { AdminOfferEdit } from "./Pages/AdminOfferEdit";
import { AdminOffersList } from "./Pages/AdminOffersList";
import { OffersRepo } from "./Models/OffersRepo";
import { OnlineStorage } from "./Services/Storage/OnlineStorage";
import { JobOfferConverter } from "./Models/JobOfferConverter";
import { AdminRouter } from "./Services/AdminRouter";
import { RecruitRouter } from "./Services/RecruitRouter";


export class Main extends Div
{
    constructor()
    {
        super("Main")

        const env = "prod";

        if (env == "dev")
        {
            const adminUrl = new RefString("admin/offers").Storable("admin-url")
            const _router = new AdminRouter(adminUrl);
            const _storage = new OnlineStorage();
            const _converter = new JobOfferConverter();
            const _repo = new OffersRepo(_storage, _converter);
            const customerUrl = new RefString("order/a85f367c-c22d-4793-a903-7bdb85eb560f").Storable("customer-url")
            const _recruitRouter = new RecruitRouter(customerUrl);

            const adminContent = new DestroyingPatternContentSwitcher(adminUrl).Class("PagesSwitcher")
                .AddContent("admin/offers", () => new AdminOffersList(_repo, _router))
                .AddContent("admin/offer/:id/edit", ({ id }) => new AdminOfferEdit(_repo, _router, id))
                .AddContent("admin/apply/:id/view", ({ id }) => new ApplyView(_repo, id))
                .AddDefaultContent(() => new NotExistingPage(_recruitRouter))

            const customerContent = new DestroyingPatternContentSwitcher(customerUrl).Class("PagesSwitcher")
                .AddContent("jobs", () => new RecruitJobsListView(_repo, _recruitRouter))
                .AddContent("job/:id", ({ id }) => new OfferPreview(_repo, _recruitRouter, id))
                .AddContent("job/:id/apply", ({ id }) => new OfferApply(_repo, _recruitRouter, id))
                .AddDefaultContent(() => new NotExistingPage(_recruitRouter))

            this.Append(
                new Button("<", () => _router.GoBack()).FloatLeft(),
                new TextInput(adminUrl).WidthPercent(43).FloatLeft(),
                new TextInput(customerUrl).WidthPercent(43).FloatRight(),
                new Button("<", () => _recruitRouter.GoBack()).FloatRight(),
                adminContent.WidthPercent(49).FloatLeft().ClearLeft(),
                customerContent.WidthPercent(49).FloatRight().ClearRight(),
            )
        }
        else if (env == "prod")
        {
            const hash = new HashRef()
            const _router = new AdminRouter(hash); 
            const _recruitRouter = new RecruitRouter(hash);
            const _storage = new OnlineStorage();
            const _converter = new JobOfferConverter();
            const _repo = new OffersRepo(_storage, _converter);

            const content = new DestroyingPatternContentSwitcher(hash).Class("PagesSwitcher")
                .AddContent("admin/offers", () => new AdminOffersList(_repo, _router))
                .AddContent("admin/offer/:id/edit", ({ id }) => new AdminOfferEdit(_repo, _router, id))
                .AddContent("admin/apply/:id/view", ({ id }) => new ApplyView(_repo, id))
                .AddContent("jobs", () => new RecruitJobsListView(_repo, _recruitRouter))
                .AddContent("job/:id", ({ id }) => new OfferPreview(_repo, _recruitRouter, id))
                .AddContent("job/:id/apply", ({ id }) => new OfferApply(_repo, _recruitRouter, id))
                .AddDefaultContent(() => new NotExistingPage(_recruitRouter))

            this.Append(
                content,
            )
        }
    }
}