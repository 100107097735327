import { Div } from "@tblabs/truffle";


export class Header extends Div
{
    constructor(text)
    {
        super();
        this.Style(`font-size:28px; xbackground: #fafafa; text-align:left; padding: 8px 12px; margin-top: 48px; margin-bottom: 0; border-top: 2px solid #ccc; border-bottom: 1px dashed #ddd`);
        this.Append(text);
    }
}
