import { RefString } from "@tblabs/truffle";


export class JobOfferQuestionEntry
{
    public Question = new RefString();
    public Answer = new RefString();

    constructor(question, answer)
    {
        this.Question.value = question || "";
        this.Answer.value = answer || "";
    }
}
