import { Collection, CollectionView, Div, Link, TableBuilder } from "@tblabs/truffle";
import { RecruitRouter } from "../Services/RecruitRouter";
import { Section } from "../Components/Section";
import { OffersRepo } from "../Models/OffersRepo";


export class RecruitJobsListView extends Div
{
    constructor(private _repo: OffersRepo, private _router: RecruitRouter)
    {
        super();
    }

    protected async OnAppend(): Promise<void>
    {
        const offers = await this._repo.GetAllOffers();
        const offersCollection = new Collection(offers);

        this.Append(
            new Section(`Dostępne wakaty`, [
                new TableBuilder(new CollectionView(offersCollection).Refresh())
                    .AddColumn("Stanowisko", x => new Link(x.Title.value).OnClick(() => this._router.GoToOfferPreview(x)))
                    .AddColumn("Rekrutacja", x => x.IsRecruitmentOpen.value ? "✅ Otwarta" : "✖️ Zamknięta")
                    .Build(),
            ])
        )
    }
}
