import { Message } from './Message';


export class DeleteCommand extends Message
{
    constructor(
        Id: string,
        Folder: string)
    {
        super("Delete", { Id, Folder });
    }
}
