import { Collection, Div, Line, Link, TableBuilder } from "@tblabs/truffle";
import { OffersRepo } from "../Models/OffersRepo";
import { AdminRouter } from "../Services/AdminRouter";
import { JobOffer } from "../Models/JobOffer";
import { MiniSnack } from "../Components/MiniSnack";
import { Box } from "../Components/Box";


export class AdminOffersList extends Div
{
    constructor(private _repo: OffersRepo, private _router: AdminRouter)
    {
        super();
        this.Padding(12, 16)
    }

    protected async OnAppend(): Promise<void> 
    {
        const offers = await this._repo.GetAllOffers();
        const offersCollection = new Collection(offers);

        const applys = await this._repo.GetAllApplys();
        const applysCollection = new Collection(applys);

        this.Append(
            new TableBuilder(offersCollection)
                .AddColumn("Stanowisko", x => new Link(x.Title.value).OnClick(() => this._router.GoToOfferEdit(x)))
                .AddColumn("Rekrutacja", x => x.IsRecruitmentOpen.value ? "✅ Otwarta" : "✖️ Zamknięta")
                .AddColumn("Opcje", x => [
                    new Link("✖️").OnClick(async () =>
                    {
                        if (confirm(`Usunąć ${x.Title.value}?`))
                        {
                            await this._repo.Delete(x.Id, "Offers")
                            offersCollection.Remove(x)
                            new MiniSnack("Oferta usunięta")
                        }
                    }),])
                .Build(),
            new Box(
                new Link("➕ Dodaj").OnClick(() => offersCollection.Add(new JobOffer()))
            ).MarginTop(16).MarginBottom(32),

            new Line(),
            new TableBuilder(applysCollection)
                .AddColumn("Kandydatury", x => [
                    "🧑‍💼 ",
                    new Link(x.Recruit.Name.value).OnClick(() => this._router.GoToApplyView(x))
                ])
                .AddColumn("Telefon & Email", x => `${x.Recruit.Phone.value} / ${x.Recruit.Email.value}`)
                .AddColumn("Opcje", x => [
                    new Link("✖️").OnClick(async () =>
                    {
                        if (confirm(`Usunąć ${x.Recruit.Name.value}?`))
                        {
                            await this._repo.Delete(x.Id, "Applys")
                            applysCollection.Remove(x)
                            new MiniSnack("Rekrut usunięty")
                        }
                    }),
                ])
                .Build(),
        )
    }
}
