import { Collection, RefBool, RefString } from "@tblabs/truffle";
import { v4 as uuidv4 } from 'uuid';
import { JobOfferDescriptionEntry } from "./JobOfferDescriptionEntry";
import { JobOfferQuestionEntry } from "./JobOfferQuestionEntry";


export class JobOffer
{
    public Id: string = "";
    public Title = new RefString("(nowe stanowisko, kliknij by edytować)");
    public IsRecruitmentOpen = new RefBool();
    public Description = new Collection<JobOfferDescriptionEntry>();
    public Form = new Collection<JobOfferQuestionEntry>();

    constructor(id?: string)
    {
        if (id)
            this.Id = id;
        else this.Id = uuidv4();
    }
}
