import { Div } from "@tblabs/truffle";


export class Box extends Div
{
    constructor(...cmps)
    {
        super();

        this.Append(...cmps);
    }
}

export class ParamsBox extends Div
{
    constructor(...cmps)
    {
        super();
        this.Class("params")

        this.Append(...cmps);
    }
}
