import { Checkbox, Div, Line, Link, MultilineInput, Span, TableBuilder, TextInput } from "@tblabs/truffle";
import { AdminRouter } from "../Services/AdminRouter";
import { OffersRepo } from "../Models/OffersRepo";
import { PrimaryButton } from "../Components/PrimaryButton";
import { Center } from "../Components/Center";
import { JobOfferDescriptionEntry } from "../Models/JobOfferDescriptionEntry";
import { JobOfferQuestionEntry } from "../Models/JobOfferQuestionEntry";
import { MiniSnack } from "../Components/MiniSnack";


export class AdminOfferEdit extends Div
{
    constructor(private _repo: OffersRepo, private _router: AdminRouter, private offerId: string)
    {
        super();
    }

    protected async OnAppend(): Promise<void>
    {
        const offer = await this._repo.GetOneOffer(this.offerId);

        if (!offer)
        {
            throw new Error(`No offer`);
        }

        this.Append(
            new TextInput(offer.Title).Placeholder("Nazwa stanowiska"),
            new Checkbox(offer.IsRecruitmentOpen, "Rekrutacja otwarta"),
            new Line(),
            new Span("Opis oferty").FontSize(32),
            new TableBuilder(offer.Description)
                .AddColumn("Pole", x => [
                    new TextInput(x.Label).WidthPercent(82).MarginRight(12),
                    offer.Description.CanSwapUp(x) && new Link("☝️")
                        .OnClick(() => offer.Description.SwapUp(x)),
                    offer.Description.CanSwapDown(x) && new Link("👇")
                        .OnClick(() => offer.Description.SwapDown(x)),
                    new Link("✖️")
                        .OnClick(() => offer.Description.Remove(x)),
                    new MultilineInput(x.Text).Rows(4).MarginTop(8),
                ])
                .Build(),
            new Link("➕ Dodaj pole").OnClick(() => offer.Description.Add(new JobOfferDescriptionEntry("", ""))),

            new Line(),
            new Span("Pytania do kandydata").FontSize(32),
            new TableBuilder(offer.Form)
                .AddColumn("Pole", x => new TextInput(x.Question))
                .AddColumn("Opcje", x => [
                    offer.Form.CanSwapUp(x) && new Link("☝️").OnClick(() => offer.Form.SwapUp(x)),
                    offer.Form.CanSwapDown(x) && new Link("👇").OnClick(() => offer.Form.SwapDown(x)),
                    new Link("✖️").OnClick(() => offer.Form.Remove(x)),
                ])
                .Build(),
            new Link("➕ Dodaj pytanie").OnClick(() => offer.Form.Add(new JobOfferQuestionEntry("", ""))),

            new Center(
                new PrimaryButton("Zapisz", async () =>
                {
                    await this._repo.Update(offer);
                    new MiniSnack("Zapisano");
                    this._router.GoBack()
                })
            ).Margin(32)
        )
    }
}
