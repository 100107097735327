import { Message } from './Message';


export class ReadQuery extends Message
{
    constructor(Id: string, Folder: string)
    {
        super("Read", { Id, Folder });
    }
}
